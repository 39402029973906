import React from 'react';
import _ from 'lodash';

import {safePrefix, markdownify, Link} from '../utils';
import CtaButtons from './CtaButtons';

export default class SectionHero extends React.Component {
    render() {
        return (
            <section id={_.get(this.props, 'section.section_id')} className={'block hero-block outer bg-' + _.get(this.props, 'section.bg')}>
              <div className="inner">
                <div className="grid">
                  {_.get(this.props, 'section.image') && 
                  <div className={'cell block-preview ' + _.get(this.props, 'section.image_position')}>
                    <img src={safePrefix(_.get(this.props, 'section.image'))} alt={_.get(this.props, 'section.title')} />
                  </div>
                  }
                  <div className="cell block-content">
                    {_.get(this.props, 'section.title') && 
                    <h2 className="block-title">{_.get(this.props, 'section.title')}</h2>
                    }
                    <div className="block-copy">
                      {markdownify(_.get(this.props, 'section.content'))}
                    </div>
                    {_.get(this.props, 'section.actions') && 
                      <CtaButtons {...this.props} actions={_.get(this.props, 'section.actions')} />
                    }
                  </div>
                </div>
              </div>
            </section>
        );
    }
}
