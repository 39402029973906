import React from 'react';
import _ from 'lodash';

import {htmlToReact, safePrefix} from '../utils';

export default class SectionBenefits extends React.Component {
    render() {
        return (
          <section id={_.get(this.props, 'section.section_id')} className={'block pricing-block bg-' + _.get(this.props, 'section.bg')}>
              <div className="inner">
                <div className="grid">
                    {_.get(this.props, 'section.title') && 
                    <h2 className="block-title">{_.get(this.props, 'section.title')}</h2>
                    }
                    {_.map(_.get(this.props, 'section.benefits'), (benefit, benefit_idx) => (
                  <div key={benefit_idx} className='benefit'>
                    <div className="benefit-name">
                    <p>+ {_.get(benefit, 'name')}</p>
                    </div>
                  </div>
                  ))}
                </div>
              </div>
            </section>
        );
    }
}
